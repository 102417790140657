export const helpUrls = {
  customerSupport: 'https://t.me/ProjectSerum',
  customerSupportZh: 'https://t.me/ProjectSerum_Chinese',
  contactEmail: 'mailto:support@tokina.io',
  discord: 'https://discord.gg/shf6Hf92mj',
  telegram: 'https://t.me/tokina_io',
  twitter: 'https://twitter.com/tokina_io',
  docs: 'https://docs.tokina.io/',
  github: 'https://github.com/project-serum',
  projectSerum: 'https://projectserum.com/',
  developerResources: 'https://projectserum.com/developer-resources',
  solanaBeach: 'https://solanabeach.io',
  tokina: 'https://tokina.io/#/market/GfRXqKkwtbEjEoYfKMPr5m3QoG2oVVv8sNgjihsV9oA3',
};
